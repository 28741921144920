import * as actionTypes from './constants';
import { fromJS } from 'immutable';

const defaultState = fromJS({
    device: 'desktop',
})

const reducer = ((state = defaultState, action) => {
    switch(action.type) {
        case actionTypes.SET_DEVICE:
            return state.set('device', action.data);
        default:
            return state;
    }
})

export default reducer;