export const MenuItems = [
    {
        label: '解决方案', // 解决方案
        key: 'solution',
        hasArrow: true
    },
    {
        label: '代理商计划', // 代理商计划
        key: 'agent',
        hasArrow: false,
        path: '/agent-plan'
    },
    {
        label: '帮助中心', // 帮助中心
        key: 'help',
        hasArrow: true
    },
    {
        label: 'SD-WAN', // SD-WAN
        key: 'product',
        hasArrow: true
    },
    {
        label: '海外IP代理', // 海外IP代理
        key: 'staticIP',
        hasArrow: false,
        path: ''
    },
]
