import React, { useEffect, useState, useCallback } from 'react';
// import { renderRoutes } from 'react-router-config';
import renderRoutes from '@/routes/renderRoutes';
import { Container, Header, Content, Footer } from './style';
import { BackTop } from 'antd';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import FooterPanel from '@/components/FooterPanel';
import { connect } from 'react-redux';
import { ReactComponent as ArrowDown } from '@/assets/svg/arrowDown.svg';
import { ReactComponent as IconMenu } from '@/assets/svg/menu.svg';
import logoPic from '@/assets/png/logo.png';
import logoWPic from '@/assets/png/logo_w.png';
import NavComponent from './NavComponents/index';
import SiderNav from './SiderNav';
import { MenuItems } from '@/routes/menu';
import { openIpflyPath, openMainClientPath } from '@/utils/config';

const whitePathList = [
    '/index',
    '/live-box',
    '/operation-box',
    '/sdwan-network',
    '/sdwan-app',
    '/static-ip',
    '/agent-plan',
    '/partners',
    '/faq',
]

function Home(props) {
    const { route, history } = props;
    const { device } = props;
    const { pathname, search } = useLocation();
    const [headerWhite, setHeaderWhite] = useState(false);
    const [headerSticky, setHeaderSticky] = useState(false);
    const [currentExpend, setCurrentExpend] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showSubNav, setShowSubNav] = useState(false);
    const [siderNavVisible, setSiderNavVisible] = useState(false);

    useEffect(() => {
        function scrollhandler() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            if (scrollTop >= 75) {
                setHeaderSticky(true);
            } else {
                setHeaderSticky(false);
            }
        }
        scrollhandler();
        window.addEventListener('scroll', scrollhandler);
        return () => {
            window.removeEventListener('scroll', scrollhandler);
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setShowSubNav(false);
        setCurrentExpend('');
        setCurrentIndex(0);
        // 回到顶部
        window.scrollTo(0, 0);
        if (whitePathList.includes(pathname)) {
            setHeaderWhite(true);
        } else {
            setHeaderWhite(false);
        }
    }, [pathname, search])

    useEffect(() => {
        if (!showSubNav) {
            setCurrentExpend('');
            setCurrentIndex(0);
        }
        const handleClickOutside = (event) => {
            if (showSubNav) {
                let arr = [];
                getParent(event.target); // 执行递归
                function getParent(obj) {
                    if (obj.parentNode) { // 往树的上层追溯，直到最上层
                        arr.push(obj.parentNode);
                    } else {
                        return;
                    }
                    getParent(obj.parentNode); // 递归追溯源头
                }
                let navPicker = document.getElementsByClassName('nav-picker')[0];
                let subNavPicker = document.getElementsByClassName('sub-nav-picker')[0];
                if (!arr.includes(navPicker) && !arr.includes(subNavPicker)) {
                    setShowSubNav(false);
                }
            }
        }
        document.addEventListener('mousedown', handleClickOutside, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [showSubNav]);

    const getHeaderClass = () => {
        const classArr = [];
        if (headerWhite) {
            classArr.push('header-white');
        }
        if (headerSticky) {
            classArr.push('header-sticky');
        }
        return classArr.join(' ');
    }

    const clickMenu = (item, index) => {
        setCurrentExpend(item.key);
        setCurrentIndex(index);
        if (item.hasArrow) {
            if (currentExpend === item.key) {
                setShowSubNav(show => {
                    return show ? false : true;
                });
            } else {
                setShowSubNav(true);
            }
        } else {
            setShowSubNav(false);
            if (item.path) {
                history.push(item.path);
            } else if (item.key === 'staticIP') {
                openIpflyPath();
            }
        }
    }

    const hidePanel = () => {
        setShowSubNav(false);
    }

    const handleChangeSiderNav = useCallback((val) => {
        setSiderNavVisible(val);
    }, [])

    return <Container>
        <Header className={getHeaderClass()}>
            <div className="nav-wrap">
                <div className="left-wrap">
                    <div className="logo" onClick={() => history.push('/')}>
                        <img src={headerWhite && !headerSticky ? logoWPic : logoPic} width="100%" alt="logo" />
                    </div>
                    {
                        device !== 'mobile' ? <div className="menu-list nav-picker">
                            {
                                MenuItems.map((item, index) => {
                                    return <div className="menu-item" key={item.key} onClick={() => clickMenu(item, index)}>
                                        {item.label}
                                        {item.hasArrow ? <ArrowDown className="arrow-svg-icon" /> : null}
                                    </div>
                                })
                            }
                            {
                                showSubNav ? <NavComponent
                                    history={history}
                                    current={currentExpend}
                                    currentIndex={currentIndex}
                                    hidePanel={hidePanel}
                                /> : null
                            }
                        </div> : null
                    }
                </div>
                <div className="right-wrap">
                    {
                        device !== 'mobile' ? <>
                            <div className='cs-btn-text-plain' onClick={() => openMainClientPath('/login')}>登录</div>
                            <div className='cs-btn-round' onClick={() => openMainClientPath('/register')}>注册</div>
                        </> : null
                    }
                    { device === 'mobile' ? <IconMenu className="menu-btn" onClick={() => setSiderNavVisible(true)} /> : null }
                </div>
            </div>
        </Header>
        <Content>
            {renderRoutes(route.routes)}
        </Content>
        <Footer>
            <FooterPanel history={history} device={device} />
        </Footer>
        <SiderNav
            history={history}
            pathname={pathname}
            search={search}
            visible={siderNavVisible}
            onChange={handleChangeSiderNav}
        />
        <BackTop style={{ right: '36px', bottom: '36px' }}>
            <div
                style={{
                    width: '50px',
                    height: '50px',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.1)',
                    borderRadius: '50%',
                    textAlign: 'center',
                    lineHeight: '50px',
                    fontSize: '28px',
                    color: '#909090',
                }}
            ><VerticalAlignTopOutlined /></div>
        </BackTop>
    </Container>
}
// 映射 Redux 全局的 state 到组件的 props 上
const mapStateToProps = (state) => ({
    device: state.getIn(['app', 'device']),
});

// 映射 dispatch 到 props 上
const mapDispatchToProps = (dispatch) => {
    return {
    }
};

// 将 ui 组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Home));
