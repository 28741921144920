import * as actionTypes from './constants';
import { fromJS } from 'immutable';

const changeData = (type, data) => ({
    type: type,
    data: fromJS(data)
})

export const toggleDevice = (device) => {
    return dispatch => {
        dispatch(changeData(actionTypes.SET_DEVICE, device))
    }
}
