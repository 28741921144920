import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import routes from '@/routes';
import isNewVersion from '@/utils/versionUpdate';

const DefaultTitle = '星鹿跨境电商加速盒';
const DefaultKeywords = '跨境电商直播加速, 星鹿加速盒, 跨境电商解决方案, tiktok直播海外直播解决方案, 跨境电商直播网络优化';
const DefaultDescription = '星鹿跨境电商加速盒官网，全球节点覆盖，智能跨境专线+静态住宅IP，稳定高效、超低延迟，一站式解决跨境网络问题，为跨境业务量身定制，无需复杂配置，即插即用。';
 
// 创建meta标签
function createMetaTag(name, content) {
    var metaTag = document.head.querySelector('meta[name="' + name + '"]');
    
    if (metaTag) {
        metaTag.setAttribute('content', content);
    } else {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('name', name);
        metaTag.setAttribute('content', content);
        document.head.appendChild(metaTag);
    }
}

function RouteWatcher() {
    const { pathname } = useLocation();
    const [pageOptions, setPageOptions] = useState({});

    useEffect(() => {
        const options = {}
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].pageTitle) {
                options[routes[i].path] = {
                    title: routes[i].pageTitle,
                    keywords: routes[i].pageKeywords,
                    description: routes[i].pageDescription,
                }
            }
            if (routes[i].routes) {
                for (let j = 0; j < routes[i].routes.length; j++) {
                    if (routes[i].routes[j].pageTitle) {
                        options[routes[i].routes[j].path] = {
                            title: routes[i].routes[j].pageTitle,
                            keywords: routes[i].routes[j].pageKeywords,
                            description: routes[i].routes[j].pageDescription,
                        }
                    }
                }
            }
        }
        setPageOptions({ ...options });
        function visibilitychangeHandler() {
            setTimeout(() => {
                changeTitles(options, window.location.pathname);
            }, 20)
        }
        window.addEventListener("visibilitychange", visibilitychangeHandler);
        window.onblur = visibilitychangeHandler;
        window.onfocus = visibilitychangeHandler;
        return () => {
            window.removeEventListener("visibilitychange", visibilitychangeHandler);
        }
    }, [])
    
    useEffect(() => {
        changeTitles(pageOptions, pathname);
        // 检查版本更新
        isNewVersion();
    }, [pageOptions, pathname]);

    const changeTitles = (options, pathname) => {
        let title = DefaultTitle;
        let keywords = DefaultKeywords;
        let description = DefaultDescription;
        if (options[pathname]) {
            if (options[pathname].title) {
                title = options[pathname].title;
            }
            if (options[pathname].keywords) {
                keywords = options[pathname].keywords;
            }
            if (options[pathname].description) {
                description = options[pathname].description;
            }
        }
        document.title = title;
        createMetaTag('keywords', keywords);
        createMetaTag('description', description);
    }
    
    return null; // 这是一个无状态组件，不需要渲染任何内容
}
 
export default RouteWatcher;