import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    position: relative;
`

export const Header = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    color: #0C1939;
    transition: all 0.2s ease-out 0s;
    .nav-wrap {
        width: 100%;
        max-width: 1312px;
        padding: 0 16px;
        height: 90px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-wrap {
            display: flex;
            align-items: center;
            .logo {
                width: 134px;
                cursor: pointer;
                margin-right: 32px;
            }
            .menu-list {
                display: flex;
                align-items: center;
                position: relative;
                .menu-item { 
                    font-family: 'SourceHanSansCN-Medium';
                    margin-left: 64px;
                    cursor: pointer;
                }
            }
        }
        .right-wrap {
            display: flex;
            align-items: center;
        }
    }
    &.header-white {
        color: #fff;
    }
    &.header-sticky {
        position: sticky;
        background: #fff;
        color: #0C1939;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 10px -10px;
        z-index: 2;
    }
    @media (max-width: 1024px) {
        .nav-wrap {
            .left-wrap {
                .menu-list {
                    .menu-item { 
                        margin-left: 32px;
                    }
                }
            }
        }
    }
    @media (max-width: 860px) {
        .nav-wrap {
            .left-wrap {
                .menu-list {
                    .menu-item { 
                        margin-left: 12px;
                    }
                }
            }
        }
    }
    @media (max-width: 768px) {
        .nav-wrap {
            width: 100%;
            height: 60px;
            max-width: none;
            padding: 0 20px;
            .left-wrap {
                .logo {
                    width: 126px;
                }
            }
            .right-wrap {
                .menu-btn {
                    width: 24px;
                    height: auto;
                    fill: currentColor;
                    margin-left: 12px;
                    cursor: pointer;
                }
            }
        }
    }
`

export const Content = styled.div`
    
`

export const Footer = styled.div`
    
`