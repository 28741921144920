import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = document.getElementById('root');

ReactDOM.createRoot(root).render(<App />);

// let lastRefreshTime = null;

window.onerror = function (errorMessage, scriptURI, lineNo, columnNo, error) {
    console.log('errorMessage: ' + errorMessage); // 异常信息
    console.log('scriptURI: ' + scriptURI); // 异常文件路径
    console.log('lineNo: ' + lineNo); // 异常行号
    console.log('columnNo: ' + columnNo); // 异常列号
    console.log('error: ' + error); // 异常堆栈信息
    // if (!lastRefreshTime || (new Date()).getTime() - lastRefreshTime.getTime() > 30000) {
    //     lastRefreshTime = new Date();
    //     window.location.href = window.location.origin; // 刷新页面
    // }
}
