import React, { lazy, Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import Home from '@/application/Home';
import Page404 from '@/application/Page404';
import PageLoading from '@/baseUI/PageLoading';
const IndexComponent = lazy(() => import('@/application/Index'));

const OverseasLiveComponent = lazy(() => import('@/application/OverseasLive'));
const CrossBorderECommerceComponent = lazy(() => import('@/application/CrossBorderECommerce'));
const SocialMediaOperationComponent = lazy(() => import('@/application/SocialMediaOperation'));
const AccountMatrixComponent = lazy(() => import('@/application/AccountMatrix'));
const CrossBorderOfficeComponent = lazy(() => import('@/application/CrossBorderOffice'));
const GameComponent = lazy(() => import('@/application/Game'));

const AgentPlanComponent = lazy(() => import('@/application/AgentPlan'));
const ServicePartnersComponent = lazy(() => import('@/application/ServicePartners'));

const FAQComponent = lazy(() => import('@/application/FAQ'));

const SdwanNetworkComponent = lazy(() => import('@/application/SdwanNetwork'));
const SdwanAppComponent = lazy(() => import('@/application/SdwanApp'));

const PdfPageComponent = lazy(() => import('@/application/PdfPage'));
const CustomerManagerComponent = lazy(() => import('@/application/ActivityPages/CustomerManager'));

const SuspenseComponent = Component => props => {
    return (
        <Suspense fallback={<PageLoading />}>
            <Component {...props}></Component>
        </Suspense>
    )
}

const navRoutes = [
    {
        path: '/index',
        component: SuspenseComponent(IndexComponent),
        pageTitle: '星鹿跨境电商加速盒-首页',
        pageKeywords: '跨境电商直播加速, 星鹿加速盒, 跨境电商解决方案, tiktok直播海外直播解决方案, 跨境电商直播网络优化',
        pageDescription: '星鹿跨境电商加速盒官网，全球节点覆盖，智能跨境专线+静态住宅IP，稳定高效、超低延迟，一站式解决跨境网络问题，为跨境业务量身定制，无需复杂配置，即插即用。'
    },
    {
        path: '/overseas-live',
        component: SuspenseComponent(OverseasLiveComponent),
        pageTitle: '海外直播解决方案-星鹿跨境电商加速盒',
        pageKeywords: '海外直播加速, 直播解决方案, 星鹿加速盒, 跨境直播卡顿解决, 海外直播画质提升, 直播网络优化方案',
        pageDescription: '星鹿跨境电商加速盒针对海外直播场景精心打造专业加速方案，有效降低延迟、提升画质与音质，确保直播全程流畅，助您轻松吸引海外观众，拓展国际影响力。'
    },
    {
        path: '/e-commerce',
        component: SuspenseComponent(CrossBorderECommerceComponent),
        pageTitle: '跨境电商解决方案-星鹿跨境电商加速盒',
        pageKeywords: '跨境电商加速, 跨境电商解决方案, 星鹿加速盒, 电商平台访问加速, 跨境订单处理优化, 跨境电商网络支撑',
        pageDescription: '星鹿加速盒为跨境电商提供高效网络加速，极速访问各大电商平台，流畅处理海量订单，保障数据安全，提升交易速度和用户体验。'
    },
    {
        path: '/social-media',
        component: SuspenseComponent(SocialMediaOperationComponent),
        pageTitle: '社媒运营解决方案-星鹿跨境电商加速盒',
        pageKeywords: '社媒运营加速, 社交媒体加速, 星鹿加速盒, 星鹿跨境电商加速, 社媒运营网络加速, 海外社交媒体推广加速, Facebook 加速, Instagram 加速, Twitter 加速',
        pageDescription: '星鹿跨境电商加速盒助力跨境社媒运营，快速发布内容、精准互动，消除网络阻碍，让您在 Facebook、Instagram、Twitter 等海外社交媒体平台游刃有余，深度触达全球目标受众。'
    },
    {
        path: '/account-matrix',
        component: SuspenseComponent(AccountMatrixComponent),
        pageTitle: '账号矩阵解决方案-星鹿跨境电商加速盒',
        pageKeywords: '账号矩阵管理, 跨境电商账号管理, 星鹿加速盒, 星鹿跨境电商, 账号矩阵加速, 多账号管理加速, 跨境营销账号网络, 账号安全与加速协同',
        pageDescription: '探索星鹿跨境电商加速盒如何赋能跨境电商账号矩阵构建，在保障多账号稳定、安全运营的同时，加速账号间数据交互与协同，提升整体营销效率，解锁跨境电商新势能。'
    },
    {
        path: '/office',
        component: SuspenseComponent(CrossBorderOfficeComponent),
        pageTitle: '跨境办公解决方案-星鹿跨境电商加速盒',
        pageKeywords: '跨境办公加速, 跨境办公解决方案, 星鹿加速盒, 星鹿跨境电商加速, 跨境办公网络优化, 海外办公协同加速, 云办公平台访问加速, 跨境团队沟通提速',
        pageDescription: '为跨境办公场景量身定制，星鹿跨境电商加速盒确保海外团队顺畅访问办公资源、高效开展视频会议、实时协同文档编辑，打破地域限制，打造便捷、高效的跨境办公生态。'
    },
    {
        path: '/game',
        component: SuspenseComponent(GameComponent),
        pageTitle: '游戏解决方案-星鹿跨境电商加速盒',
        pageKeywords: '游戏加速, 游戏网络加速, 星鹿加速盒, 星鹿跨境电商加速, 游戏海外加速, 热门游戏跨境畅玩, 游戏延迟降低, 游戏网络优化',
        pageDescription: '星鹿跨境电商加速盒解锁游戏跨境加速新体验，无论是热门端游还是手游，助您突破网络瓶颈，超低延迟畅玩全球游戏佳作，与国际玩家并肩竞技。'
    },
    {
        path: '/agent-plan',
        component: SuspenseComponent(AgentPlanComponent),
        pageTitle: '代理商计划-星鹿跨境电商加速盒',
        pageKeywords: '星鹿加速盒代理商, 跨境业务代理商计划, 星鹿跨境电商加速, 代理商计划, 代理招募条件, 跨境电商代理优势, 代理扶持政策',
        pageDescription: '星鹿跨境电商加速盒推出极具吸引力的代理商计划，详述招募要求、权益保障及全方位扶持政策，助力代理商轻松切入市场，收获丰厚商业回报。'
    },
    {
        path: '/partners',
        component: SuspenseComponent(ServicePartnersComponent),
        pageTitle: '合作伙伴-星鹿跨境电商加速盒',
        pageKeywords: '星鹿加速盒合作伙伴, 跨境业务合作伙伴, 星鹿跨境电商加速, 合作伙伴招募, 跨境电商合作项目, 战略合作伙伴, 合作共赢跨境电商',
        pageDescription: '在此了解星鹿跨境电商加速盒的合作伙伴生态，诚邀各界有志之士加入，凭借卓越网络加速产品，协同发力，深挖跨境电商潜力市场。'
    },
    {
        path: '/faq',
        component: SuspenseComponent(FAQComponent),
        pageTitle: '常见问题-星鹿跨境电商加速盒',
        pageKeywords: '星鹿加速盒常见问题, 跨境电商加速盒问题解答, FAQ, 常见问题解答, 网络加速疑问, 跨境加速盒使用问题',
        pageDescription: '汇总星鹿跨境电商加速盒用户关心的各类常见问题，涵盖产品功能、安装使用、故障排查等多方面，一一给予清晰、权威解答，助您顺畅使用加速盒。'
    },
    {
        path: '/sdwan-network',
        component: SuspenseComponent(SdwanNetworkComponent),
        pageTitle: 'SD-WAN混合组网产品-星鹿跨境电商加速盒',
        pageKeywords: '星鹿 SD - WAN, 混合组网, 网络融合, SD - WAN 产品优势, 多链路协同',
        pageDescription: '星鹿 SD - WAN 混合组网产品，灵活整合多种网络链路，智能调配资源，为企业打造高可靠、高性能的定制化网络架构，满足复杂业务场景下的多元网络需求。'
    },
    {
        path: '/sdwan-app',
        component: SuspenseComponent(SdwanAppComponent),
        pageTitle: 'SD-WAN应用加速产品-星鹿跨境电商加速盒',
        pageKeywords: '星鹿 SD - WAN, 应用加速, 企业应用优化, SD - WAN 加速技术, 业务流程提速',
        pageDescription: '星鹿 SD - WAN 应用加速产品聚焦企业关键应用，运用前沿加速技术削减延迟、提升带宽利用率，确保 ERP、CRM、视频会议等应用流畅运转，赋能企业业务创新发展。'
    },
]

const routes = [
    {
        path: '/404',
        component: Page404,
    },
    {
        path: '/product',
        component: PdfPageComponent,
        pageTitle: '星鹿加速盒使用手册-星鹿跨境电商加速盒',
        pageKeywords: '星鹿加速盒使用手册, 加速盒操作指南, 产品操作指南, 安装步骤详解, 功能使用说明',
        pageDescription: '星鹿跨境电商加速盒使用手册，图文并茂、步骤清晰，全方位指导用户精准掌握使用技巧，充分发挥产品效能。'
    },
    {
        path: '/customer-manager',
        component: CustomerManagerComponent,
    },
    {
        path: '/',
        component: Home,
        routes: [{
            path: '/',
            exact: true,
            render: () => ( <Redirect to={'/index'} /> )
        },
        ...navRoutes,
        {
            path: '*',
            render: () => ( <Redirect to={'/404'} /> ),
        }]
    }
]

export default routes;
