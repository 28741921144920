import React from 'react';
import styled from 'styled-components';
import logoPic from '@/assets/png/logo_w.png';
// import { ReactComponent as WechatPic } from './images/wechat.svg';
// import { ReactComponent as BilibiliPic } from './images/bilibili.svg';
// import { ReactComponent as TiktokPic } from './images/tiktok.svg';
import wepayPic from './images/wepay.png';
import alipayPic from './images/alipay.png';
import { AppDataByDomain, openXinglooBoxPath, openXinglooDocPath, openXinglooNewsPath } from '@/utils/config';
import beianPic from '@/assets/png/beian.png';

const Container = styled.div`
    width: 100%;
    background: #0C1939;
    .xl-footer__container {
        width: 100%;
        max-width: 1312px;
        padding: 0 16px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .xl-footer__company-header {
        padding: 80px 0 24px 0;
        display: flex;
        justify-content: space-between;
    }
    .xl-footer__company-header-left {
        .xl-footer__company-logo {
            width: 168px;
            height: auto;
        }
        .xl-footer__company-desc {
            color: #E1E0E0;
            line-height: 24px;
            margin-top: 24px;
            p {
                margin-bottom: 0;
            }
        }
    }
    .xl-footer__company-header-right {
        display: flex;
        flex-wrap: wrap;
        .nav-wrap {
            min-width: 130px;
            margin-left: 50px;
            margin-bottom: 20px;
            color: #6F7B98;
            .nav-item {
                color: #FFFFFF;
                margin-top: 16px;
                cursor: pointer;
            }
        }
    }
    .xl-footer__company-bottom {
        width: 100%;
        height: 118px;
        border-top: 1px solid #254076;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .xl-footer__company-pay {
            height: 100%;
            display: flex;
            align-items: center;
            .xl-footer__company-pay-icon {
                width: 32px;
                height: 32px;
                margin-right: 16px;
            }
        }
    }
    .xl-footer__company-contract {
        margin-top: 12px;
        display: flex;
        align-items: center;
        .contract-icon {
            width: 24px;
            height: 24px;
            margin-right: 12px;
        }
    }
    .xl-footer__copyright {
        color: #E1E0E0;
        display: flex;
        flex-wrap: wrap;
        .xl-footer__network-button {
            margin-left: 20px;
            color: #E1E0E0;
            display: flex;
            align-items: center;
            .xl-footer__network-button-img {
                width: 16px;
                height: auto;
                margin-right: 4px;
            }
        }
    }
    @media (max-width: 768px) {
        .xl-footer__company-header {
            padding: 28px 0 24px 0;
            flex-wrap: wrap;
        }
        .xl-footer__company-header-left {
            order: 2;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .xl-footer__company-logo {
                width: 151px;
                margin-top: 18px;
                margin-bottom: 12px;
            }
            .xl-footer__copyright {
                display: flex;
                flex-direction: column;
                align-items: center;
                .xl-footer__network-button {
                    margin-left: 0;
                    margin-top: 8px;
                    font-size: 12px;
                    .xl-footer__network-button-img {
                        width: 12px;
                        height: auto;
                        margin-right: 2px;
                    }
                }
            }
        }
        .xl-footer__company-header-right {
            order: 1;
            width: 100%;
            justify-content: space-around;
            .nav-wrap {
                min-width: 100px;
                margin-left: 0;
            }
        }
        .xl-footer__company-bottom {
            height: 84px;
            .xl-footer__company-contract {
                margin-top: 0;
            }
            .xl-footer__company-pay {
                .xl-footer__company-pay-icon {
                    width: 24px;
                    height: 24px;
                    margin-right: 12px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    @media (max-width: 374px) {
        .xl-footer__company-header-right {
            justify-content: flex-start;
            .nav-wrap {
                min-width: 130px;
            }
        }
    }
`

const Copyright = () => {
    return <div className="xl-footer__copyright">
        {
            AppDataByDomain.link ? <a
                className="xl-footer__network-button"
                href={AppDataByDomain.link}
                target="_blank"
                rel="noreferrer"
            >
                {AppDataByDomain.appName}
            </a> : <span>{AppDataByDomain.appName}</span>
        }
        <a
            className="xl-footer__network-button"
            href="https://beian.miit.gov.cn"
            target="_blank"
            rel="noreferrer"
        >
            {AppDataByDomain.publishNumber}
        </a>
        {
            AppDataByDomain.beianNumber ? <a
                className="xl-footer__network-button"
                href={`https://beian.mps.gov.cn/#/query/webSearch?code=${AppDataByDomain.beianNumber}`}
                target="_blank"
                rel="noreferrer"
            >
                <img className="xl-footer__network-button-img" src={beianPic} alt='beian' />
                川公网安备 {AppDataByDomain.beianNumber}号
            </a> : null
        }
        {
            AppDataByDomain.businessLicense ? <a
                className="xl-footer__network-button"
                href="https://tsm.miit.gov.cn/"
                target="_blank"
                rel="noreferrer"
            >
                增值电信业务经营许可证 {AppDataByDomain.businessLicense}
            </a> : null
        }
    </div>
}

// const SocialMedia = () => {
//     return <div className="xl-footer__company-contract">
//         <WechatPic className="contract-icon" />
//         <BilibiliPic className="contract-icon" />
//         <TiktokPic className="contract-icon" />
//     </div>
// }

function FooterPanel(props) {
    const { history, device } = props;
    const menuList = [{
        title: "解决方案",
        children: [{
            title: "海外直播",
            url: "/overseas-live"
        }, {
            title: "跨境电商",
            url: "/e-commerce"
        }, {
            title: "社媒运营",
            url: "/social-media"
        }, {
            title: "账号矩阵",
            url: "/account-matrix"
        }, {
            title: "跨境办公",
            url: "/office"
        }, {
            title: "游戏",
            url: "/game"
        }]
    }, {
        title: "合作",
        children: [{
            title: "代理商计划",
            url: "/agent-plan"
        }, {
            title: "合作伙伴",
            url: "/partners"
        }]
    }, {
        title: "帮助中心",
        children: [{
            title: "FAQ",
            url: "/faq"
        }, {
            title: "星鹿加速盒使用手册",
            url: "XinglooBox"
        }, {
            title: "新闻资讯",
            url: "XinglooNews"
        }, {
            title: "星鹿帮助文档",
            url: "XinglooDoc"
        }]
    }]

    const gotoPage = (url) => {
        if (url === "XinglooBox") {
            openXinglooBoxPath();
        } else if (url === "XinglooNews") {
            openXinglooNewsPath();
        } else if (url === "XinglooDoc") {
            openXinglooDocPath();
        } else {
            history.push(url);
        }
    }
    return <Container>
        <div className="xl-footer__container">
            <div className="xl-footer__company-header">
                <div className="xl-footer__company-header-left">
                    <img className="xl-footer__company-logo" src={logoPic} alt='pic' />
                    {
                        device !== 'mobile' ? <>
                            <div className="xl-footer__company-desc">
                                <p>星鹿跨境电商加速盒</p>
                                <p>畅享全球极速连接，为您开拓无限可能</p>
                            </div>
                            {/* <SocialMedia /> */}
                        </> : <Copyright />
                    }
                </div>
                <div className="xl-footer__company-header-right">
                {
                    menuList.map((item, index) => {
                        return <div className="nav-wrap" key={index}>
                            <div className="fontSize_16">{item.title}</div>
                            {
                                item.children.map((cItem, cIndex) => {
                                    return <div className="nav-item" key={cIndex} onClick={() => gotoPage(cItem.url)}>{cItem.title}</div>
                                })
                            }
                        </div>
                    })
                }
                </div>
            </div>
            <div className="xl-footer__company-bottom">
                {/* { device !== 'mobile' ? null : <SocialMedia /> } */}
                { device !== 'mobile' ? null : <div /> }
                <div className="xl-footer__company-pay">
                    <img className='xl-footer__company-pay-icon' src={wepayPic} alt='pay' />
                    <img className='xl-footer__company-pay-icon' src={alipayPic} alt='pay' />
                </div>
                { device !== 'mobile' ? <Copyright /> : null }
            </div>
        </div>
    </Container>
}

export default React.memo(FooterPanel);